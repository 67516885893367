exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adult-night-activities-js": () => import("./../../../src/pages/adult-night-activities.js" /* webpackChunkName: "component---src-pages-adult-night-activities-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-discounts-js": () => import("./../../../src/pages/discounts.js" /* webpackChunkName: "component---src-pages-discounts-js" */),
  "component---src-pages-facilities-and-accessibility-js": () => import("./../../../src/pages/facilities-and-accessibility.js" /* webpackChunkName: "component---src-pages-facilities-and-accessibility-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mini-ninjas-js": () => import("./../../../src/pages/mini-ninjas.js" /* webpackChunkName: "component---src-pages-mini-ninjas-js" */),
  "component---src-pages-ninja-birthday-parties-js": () => import("./../../../src/pages/ninja-birthday-parties.js" /* webpackChunkName: "component---src-pages-ninja-birthday-parties-js" */),
  "component---src-pages-school-groups-activities-js": () => import("./../../../src/pages/school-groups-activities.js" /* webpackChunkName: "component---src-pages-school-groups-activities-js" */),
  "component---src-pages-social-team-activities-eastbourne-js": () => import("./../../../src/pages/social-team-activities-eastbourne.js" /* webpackChunkName: "component---src-pages-social-team-activities-eastbourne-js" */),
  "component---src-pages-team-building-activities-eastbourne-js": () => import("./../../../src/pages/team-building-activities-eastbourne.js" /* webpackChunkName: "component---src-pages-team-building-activities-eastbourne-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-marketing-page-js": () => import("./../../../src/templates/marketing-page.js" /* webpackChunkName: "component---src-templates-marketing-page-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-seo-page-js": () => import("./../../../src/templates/seo-page.js" /* webpackChunkName: "component---src-templates-seo-page-js" */)
}

